import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Datoimage = ({imgnum, imgalt}) => (
  <StaticQuery
    query={graphql`
    {
      datoCmsImage {
        topimage {
          url
          pc : fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
            ...GatsbyDatoCmsFluid
          }
          sp : fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" ,w: "800", h: "1200" }) {
            ...GatsbyDatoCmsFluid
          }
          news : fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" ,w: "800", h: "800" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    `}
    render={data=> {
      const imageData = data.datoCmsImage
      const image = []
      imageData.topimage.forEach((e,key) => {
        image[key] = [e.pc,{...e.sp,media: `(max-width: 768px)`}]
      });
      return (
        <>
          <Img fluid={image[imgnum]} alt={imgalt}/>
        </>
      );
    }}
  />
);

export default Datoimage