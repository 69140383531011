import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// import { Location } from '@reach/router';
import Img from "gatsby-image";

import Layout from "../components/layout";
import Title from "../components/title";
import Inview from "../components/inview";

export default function Logisticsequipment({ data }) {
  const pageData = {
    titleClass: "logistics",
    title: "ソフトウェア事業部",
    discription: "",
    image: 31,
  };
  const seodata = {
    title: "Software Division アヴィエラン株式会社　ソフトウェア事業部.",
    seo: {
      description: "ソフトウェアであらゆる産業の基幹を支えます。最先端の技術開発で価値あるソフトウェア製品を構築します。",
      image: {
        url: data.datoCmsImage.topimage[31].url,
      },
    },
    slug: "software-division",
  };
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="pankz">
        <Link to="/">トップ</Link>
        <p>ソフトウェア事業部</p>
      </div>
      <div className="l_page_contents">
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[31].news} alt="アヴィエラン株式会社　ソフトウェア事業部" />
            <Inview c_name="title" tag="p">
              最先端の技術開発
              <span>State-of-the-art technology development </span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              ソフトウェアであらゆる産業の基幹を支えます。最先端の技術開発で価値あるソフトウェア製品を構築します。
            </Inview>
          </div>
        </div>
        <div className="box1 ">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[32].news} alt="アヴィエラン株式会社　ソフトウェア事業部" />
            <Inview c_name="title" tag="p">
              システム構築
              <span>System construction </span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              システム・アプリケーションの開発を企画からデザイン、保守運用までワンストップでご提供いたします。マッチングWEBサービス、ECサイト、顧客/在庫管理、SNS /コミュニティサイト、業務管理システムなど、お客様のご要望に合わせたシステム開発を技術と経験を持って柔軟にご提案します。
            </Inview>
          </div>
        </div>
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[33].news} alt="アヴィエラン株式会社　ソフトウェア事業部" />
            <Inview c_name="title" tag="p">
              Devops環境
              <span>Devops environment </span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              各プロセスの実行に適した様々なツールを用いて、効率よく短期間の開発サイクルでプロダクトを公開し、サービスを稼働させたままユーザーからのフィードバックを取り入れ、改善・拡張プランから実装、テスト、デプロイまでの一連のプロセスで開発を行います。
            </Inview>
          </div>
        </div>
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[34].news} alt="アヴィエラン株式会社　ソフトウェア事業部" />
            <Inview c_name="title" tag="p">
              フロントエンドエンジニアリング
              <span>Front-end engineering </span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              エンドユーザー分析、人間中心設計の考えに基づき作成したUI / UXを忠実に再現し、表示します。優れた機能を使いやすさと共にユーザーに提供するため、どのデバイスでも最適に表示されるようにシステムを設計し、フロントエンドでの高いパフォーマンスを実現します。
            </Inview>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[19].news} alt="アヴィエラン株式会社　ソフトウェア事業部" />
            <Inview c_name="title" tag="p">
              システム保守
              <span>System maintenance </span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              運営に必要な保守業務の請負をいたします。不具合の調査や修正など、サービス運用を続けるにあたって発生する障害を迅速に改善し、安心してシステム運用できるようメンテナンス支援いたします。
            </Inview>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    datoCmsImage {
      topimage {
        url
        news: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
