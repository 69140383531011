import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Datoimage from "../components/datoimage"
const Title = ({titlestyle}) => (
  <StaticQuery
    query={graphql`
    {
      datoCmsHome {
        seo {
          title
          description
          twitterCard
        }
      }

    }
    `}
    render={data=> {
    const titleClass = `pagetitle ${titlestyle.titleClass}`

    if(titlestyle.image){
      return (
        <div className={titleClass}>
          <div className="fixedtitleimg">
            <Datoimage imgnum={titlestyle.image} imgalt={titlestyle.title}/>
          </div>
          <div className="fixedtexttitle">
            <h1>{titlestyle.title}</h1>
          </div>
	      </div>
      );
    }else{
      return (
        <div className={titleClass}>
          <div className="fixedtexttitle">
            <h1>{titlestyle.title}</h1>
          </div>
	      </div>
      );
    }
    }}
  />
);

export default Title